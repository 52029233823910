.language-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--color-neutral-border-light);
    background: var(--color-global-background-light);
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-l) var(--spacing-xl);
    gap: var(--spacing-xxl);
    flex-wrap: nowrap;
    position: relative;
    z-index: 101;
}

.language-banner__content {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-l);
}

.language-banner__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-l);
  color: var(--color-neutral-text-shape-default);

  .select {
    min-width: 200px;
  }

  .select__button { 
    color: var(--color-neutral-text-shape-default);
  }
}

.language-banner__message {
  font-size: var(--font-size-s);
  color: var(--color-neutral-text-shape-default);
  line-height: 1.57;
}

.language-banner__main-button .button {
  border-radius: var(--radius-rounded);
}

@media (max-width: $breakpoint-m) {
  .language-banner {
    gap: var(--spacing-l);
  }
}

@media (max-width: $breakpoint-s)  {
  .language-banner {
    align-items: flex-start;
  }
  .language-banner__content {
    flex-direction: row;
    flex-direction: column;
    align-items: flex-start;
  }
}