@use "mixins" as *;

/* Link with underline on hover */
a.-hover-link {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

/* Link with color on hover */
a.-colored-link {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    color: var(--color-global-state-hover-text-shape-default);
    text-decoration: none;
  }
}

/* Link with color on hover */
a.-underlined-colored-link {
  &:hover,
  &.-highlighted {
    color: var(--color-global-state-hover-text-shape-default);
  }

  &:active {
    color: var(--color-global-state-hover-text-shape-dark);
  }
}

/* -hidden should be used when toggling the element visibility with Javascript */
.-hidden {
  display: none !important;
}

/* Hide element if:
   1. It's empty, OR
   2. All direct children:
      a) Hidden (have .-hidden class)
      b) Not <script> tags
See history:
- https://github.com/Contexte/contexte/commit/c8c1ab6e6d34269f49b4dd3d15ce715b7ce4743b
- https://github.com/Contexte/contexte/commit/f08916bf45ee278ad3321c68d93fe562b08021f5
- https://github.com/Contexte/contexte/commit/560d83cebdad9beeeae2804148bf0b3bc0fcbfe6
*/
.-hide-if-empty {
  &:empty {
    display: none;
  }

  &:has(*):not(:has(> :not(.-hidden):not(script))) {
    display: none;
  }
}

/* expose information to screen readers while keeping details visually hidden */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/* custom tooltip */
[hover-tooltip] {
  display: flex;

  &:hover {
    z-index: 10;
    cursor: pointer;

    &::before {
      @include font-interface-s-default;
      content: attr(hover-tooltip);
      display: block;
      position: absolute;
      bottom: auto;
      top: calc(100% + 4px);
      left: 50%;
      transform: translate(-30%);
      width: 55%;
      background-color: var(--color-feedback-info-text-shape-default);
      border-radius: var(--radius-rounded);
      color: var(--color-global-white);
      padding: var(--spacing-xs);
      line-height: 1.2;
      z-index: 100;
      animation: fade-in 300ms ease;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      bottom: auto;
      top: calc(100% + 1px);
      margin-left: 8px;
      border: 1px solid transparent;
      border-color: transparent transparent var(--color-feedback-info-text-shape-default);
      border-width: 0 6px 4px;
      animation: fade-in 300ms ease;
      z-index: 100;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  &[tooltip-position="top"] {
    &:hover {
      &::before {
        bottom: calc(100% + 4px);
        top: auto;
      }
      &::after {
        bottom: calc(100% + 1px);
        top: auto;
        border-color: var(--color-feedback-info-text-shape-default) transparent transparent;
        border-width: 4px 6px 0;
      }
    }
  }
}

/* -over-screen-m-only should be used display is based on screen size */
@media (max-width: $breakpoint-m) {
  .-over-screen-m-only {
    display: none !important;
  }
}

/* -below-screen-m-only should be used display is based on screen size */
@media (min-width: $breakpoint-m) {
  .-below-screen-m-only {
    display: none !important;
  }
}

/* -over-screen-s-only should be used display is based on screen size */
@media (max-width: $breakpoint-s) {
  .-over-screen-s-only {
    display: none !important;
  }
}

/* -below-screen-s-only should be used display is based on screen size */
@media (min-width: $breakpoint-s) {
  .-below-screen-s-only {
    display: none !important;
  }
}

/* -desktop-only should be used when the element is hidden on mobile */
@media (max-width: $breakpoint-mobile) {
  .-desktop-only {
    display: none !important;
  }
}

/* -mobile-only should be used when the element is only visible on mobile */
@media (min-width: $breakpoint-mobile) {
  .-mobile-only {
    display: none !important;
  }
}

/* Layout */

.body-main {
  @include font-sans-m-default;
  background-color: var(--color-global-background-light);
}

.layout-main {
  display: grid;
  color: var(--color-neutral-text-shape-dark);
  min-height: 100vh;

  &.-centered {
    text-align: center;
  }

  &.-locked {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.layout-footer.-locked {
  display: none;
}

@media print {
  .layout-main.-printed {
    min-height: auto;
    padding-top: 0;
  }
}

/* Experiments */

@keyframes apparition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.experiment__variant:first-child {
  animation: apparition 0s 500ms both;
}

.experiment__variant:not(:first-child) {
  display: none;
}

@media (max-width: $breakpoint-mobile) {
  [hover-tooltip]:hover::before {
    width: 65%;
  }
}

/* Highlight research terms */

.search-highlight {
  background-color: var(--color-global-highlight-surface);
  color: var(--color-global-highlight-text);
}

.-with-margin-top {
  margin-top: var(--spacing-xxl);
}

.-with-margin-bottom {
  margin-bottom: var(--spacing-xxl);
}

/* Animate appearance & disappearance */

@keyframes fade-from-bottom {
  from {
    opacity: 0;
    margin-top: 2rem;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fade-to-bottom {
  from {
    opacity: 1;
    margin-top: 0;
  }

  to {
    opacity: 0;
    margin-top: 2rem;
  }
}

.-appearing {
  animation: fade-from-bottom 0.2s both;
}

.-disappearing {
  animation: fade-to-bottom 0.05s both;
}
