.multiple-banner__container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
}

.banner__container {
  width: 100%;
}

.banner {
  position: relative;
  border-radius: var(--radius-rounded);
  padding: var(--spacing-xl) var(--spacing-s);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-s);
  flex-shrink: 0;

  .banner__cta {
    display: flex;
    text-decoration: none;
  }

  svg {
    fill: currentColor;
  }

  /* Sizes */

  &.-size-s {
    padding: var(--spacing-s);

    .banner__wrap-icon {
      padding: var(--spacing-xxxs);
      align-self: stretch;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .banner__subject {
      @include font-sans-s-semi;
    }
  }

  /* Levels */

  &.-info {
    background-color: var(--color-feedback-info-surface-contrast-dark);
    color: var(--color-feedback-info-text-shape-contrast-inverted)
  }

  &.-success {
    background-color: var(--color-feedback-success-surface-contrast-default);
    color: var(--color-feedback-info-text-shape-contrast-inverted)
  }

  &.-warning {
    background-color: var(--color-global-post-it-surface-default);
    color: var(--color-global-post-it-text-shape-default);
  }

  &.-error {
    background-color: var(--color-feedback-danger-surface-contrast-default);
    color: var(--color-feedback-info-text-shape-contrast-inverted)
  }

  &.-accordeon {
    border-radius: var(--radius-rounded);
    border: 1px solid var(--color-feedback-info-border-lighter);
    background: var(--color-feedback-info-surface-lighter);
    flex-direction: column;
    gap: var(--spacing-none);
    padding: var(--spacing-s);

    .showmore__button {
      display: flex;
      align-items: center;
      gap: var(--spacing-s);
      align-self: stretch;

      .showmore__banner-title {
        display: flex;
        align-items: center;
        gap: var(--spacing-s);
        color: var(--color-feedback-info-text-shape-darker);

        p {
          @include font-interface-m-semi;
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .showmore__banner-wrap-button {
        display: flex;
        padding: var(--spacing-none);
        align-items: flex-start;
        gap: var(--spacing-xxs);

        color: var(--color-feedback-info-text-shape-dark);
        @include font-sans-s-default;
      }
    }

    .showmore__indicator {
      appearance: none;
      border: 0 none;
      background: transparent;
      cursor: pointer;
      color: var(--color-feedback-info-text-shape-dark);

      svg {
        width: 1.23rem;
        height: 0.75rem;
        transition: transform 0.2s;
      }

      &.-open svg {
        transform: rotate(180deg);
      }
    }

    .showmore__indicator::after {
      display: none;
    }

    .showmore__container {
      background-color: var(--color-global-background-lighter);
    }

    .showmore__container.-open {
      border-radius: var(--radius-rounded);
      margin-top: var(--spacing-s);
    }

    &.-size-m {
      .showmore__button .showmore__banner-title {
        p {
          @include font-interface-s-semi;
        }

        svg {
          width: 2rem;
          height: 2rem;
        }
      }

      .showmore__indicator svg {
        width: 1.025rem;
        height: 0.625rem;
      }
    }
  }
}

.banner__wrap-icon {
  display: flex;
  padding: var(--spacing-none);
  align-items: flex-start;
  gap: var(--spacing-none);

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.banner__wrap-text {
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-s);
  flex: 1 0 0;

  .banner__wrap-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .banner__wrap-title {
      display: flex;
      align-items: flex-start;
      gap: var(--spacing-s);
      align-self: stretch;

      .banner__subject {
        flex: 1 0 0;
        @include font-sans-m-semi;
      }
    }

    .banner__body {
      display: flex;
      padding: var(--spacing-none) var(--spacing-xxl) var(--spacing-xxs) var(--spacing-none);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-none);
      align-self: stretch;
      font-weight: 400;

      a:hover,
      a:focus-visible {
        text-decoration-thickness: 0.2rem;
      }
    }
  }

  .banner__wrap-action {
    display: flex;
    padding: var(--spacing-none);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-m);
    align-self: stretch;
  }
}

.banner__section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  padding: var(--spacing-l);

  &.-info,
  &.-warning,
  &.-error {
    color: var(--color-feedback-info-text-shape-dark);
  }

  &.-success {
    color: var(--color-feedback-success-text-shape-dark);
  }

  &.-size-l,
  &.-size-l-m {
    @include font-sans-m-default;
    gap: var(--spacing-m);
    margin: 0 var(--spacing-xl);
    padding: var(--spacing-xl) 0;

    .banner__section-title {
      @include font-sans-m-semi;
      @include font-style-underline;
    }

    .banner__section-example {
      @include font-sans-m-semi;
    }

    span.-highlighted {
      @include font-sans-m-link;
    }

    .banner__section-title {
      text-decoration: underline;
      text-underline-position: under;
    }

    .banner__link {
      margin-top: var(--spacing-xxl);
      margin-bottom: var(--spacing-none);

      svg {
        fill: currentColor;
      }
    }
  }

  &.-size-m {
    @include font-sans-s-default;
    gap: var(--spacing-s);
    margin: 0 var(--spacing-l);
    padding: var(--spacing-l) 0;

    .banner__section-title {
      @include font-sans-s-semi;
      @include font-style-underline;
    }

    .banner__section-example {
      @include font-sans-s-semi;
    }

    span.-highlighted {
      @include font-sans-s-link;
    }

    .banner__link {
      margin-top: var(--spacing-xl);
    }

    .showmore__banner-title svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

/* Responsive */

@media (max-width: $breakpoint-m) {
  .banner {
    &.-size-m, &.-size-l-m {
      padding: var(--spacing-s);

      .banner__wrap-icon svg {
        width: 2rem;
        height: 2rem;
        margin-top: var(--spacing-xxxs);
      }

      .banner__wrap-text svg,
      .showmore__button svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      .banner__wrap-title .banner__subject {
        @include font-sans-s-semi;
      }

      .banner__body {
        @include font-sans-s-default;
        margin-bottom: var(--spacing-xxxs);
      }
    }

    .banner__section.-size-l-m {
      @include font-sans-s-default;
      gap: var(--spacing-s);
      margin: 0 var(--spacing-l);
      padding: var(--spacing-l) 0;

      .banner__section-title {
        @include font-sans-s-semi;
        @include font-style-underline;
      }

      .banner__section-example {
        @include font-sans-s-semi;
      }

      span.-highlighted {
        @include font-sans-s-link;
      }

      .banner__link {
        margin-top: var(--spacing-xl);
      }
    }

    &.-accordeon.-size-l-m {
      .showmore__button .showmore__banner-title {
        p {
          @include font-interface-s-semi;
        }

        svg {
          width: 2rem;
          height: 2rem;
        }
      }

      .showmore__indicator svg {
        width: 1.025rem;
        height: 0.625rem;
      }
    }
  }
}

/* Toaster */

.toaster {
  position: fixed;
  bottom: 5%;
  left: 50%;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateY(100%) translateX(-50%);

  &.-visible {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
    visibility: visible;
  }

  &.-message {
    opacity: 1;
    visibility: visible;
    bottom: unset;
    left: unset;
    right: 24px;
    transform: translate(0%, 0);
    animation: slide 5s forwards;

    @keyframes slide {
      0% {
        bottom: -100px;
      }
      10% {
        bottom: 24px;
      }
      80% {
        bottom: 24px;
      }
      100% {
        bottom: -100px;
      }
    }
  }
}
